@font-face {
  font-family: Font;
  src: url(Font.ttf);
}

@font-face {
  font-family: Header;
  src: url(Font_Header.ttf);
}

body,html{
  font-family: Font;
  background-color: black !important;
}

.App {
  text-align: center;
}

.header-text{
  text-align:center;
  font-size: 18px;
}

.header-text-about{
  color: white !important;
  text-align:center;
  font-size: calc(12x + 1.25vmin);
}

.welcome {
  font-size: calc(12px + 1.25vmin);
  color:white;
}
.welcome:hover {
  color:black
}

.subheader-text{
  font-size: 12px;
  font-style: italic;
}

@media only screen and (max-width: 600px) {
  .nav-mobile{
    margin-top:20px;
    margin-right:40px;
  }
}

.h1  {
  font-size:24px;
}

.h2{
  font-size:24px  !important;
}

.fs-lg {
  font-size:28px !important;
}

.fs-sm {
  font-size:12px !important;
}

.bg-main {
  height:100vh;
  overflow: hidden !important;
  background: url("https://www.junguru.com/junguru-main.gif") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.maxHeight {
  max-height: 100vh;
  overflow:hidden;
}

.nav-link {
  font-size:18px !important;
  font-family: Header;
}

.subheader-text-small{
  font-size: 6.5px;
  opacity:.5;
  text-align:left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-Main {
  height: 90vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
.App-header-About {
  height:80vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header-Team {
  overflow-y: hidden; /* Hide vertical scrollbar */
  overflow-x: hidden !important;
}
.team-frame{  
  width: 100%;
  height: 410px;
  border: 1px solid black;
  position: relative;
} 
.team-img { 
    max-height: 100%;  
    max-width: 100%; 
    position: absolute;  
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
}
.page-loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all .4s .2s ease-in-out;
  transition: all .4s .2s ease-in-out;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
}
.dark-mode .page-loading {
  background-color: #0b0f19;
}
.page-loading.active {
  opacity: 1;
  visibility: visible;
}
.page-loading-inner {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: opacity .2s ease-in-out;
  transition: opacity .2s ease-in-out;
  opacity: 0;
}
.page-loading.active > .page-loading-inner {
  opacity: 1;
}
.page-loading-inner > span {
  display: block;
  font-size: 1rem;
  font-weight: normal;
  color: #9397ad;
}
.dark-mode .page-loading-inner > span {
  color: #fff;
  opacity: .6;
}

.nft-logo{
  width:75px;
  margin-bottom:15px;
}

.page-spinner {
  display: inline-block;
  width: 2.75rem;
  height: 2.75rem;
  margin-bottom: .75rem;
  vertical-align: text-bottom;
  border: .15em solid #b4b7c9;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner .75s linear infinite;
  animation: spinner .75s linear infinite;
}
.dark-mode .page-spinner {
  border-color: rgba(255,255,255,.4);
  border-right-color: transparent;
}
@-webkit-keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


